import "./trans";

function renderBanner() {
  if ($(".banner .swiper-container").length !== 0) {
    const bannerList = window.banner;
    const indexContainer = $("#index-banner.swiper-wrapper");
    const isLoggedIn = $(".before-login.hide").length !== 0;

    bannerList &&
      bannerList.length !== 0 &&
      bannerList.forEach(function (item) {
        const { imageUrl, redirectUrl, redirectGroup, redirectPage, bannerType } = item;
        if (imageUrl) {
          /**
           * Redirect Group Option
           * 0 - Promo
           * 1 - Join Us
           * 2 - Lottery
           * 3 - Product
           * 4 - Others inner page (Loyalty, Customer Service)
           * 5 - 自定义
           */

          const banner = $(`
            <div class="swiper-slide" data-swiper-autoplay="5000">
                <img ${redirectUrl ? `data-redirect=${redirectUrl}` : ""} class="img" src="${imageUrl}"/>
                <div class="img-bg" style="background-image: url(${imageUrl})"></div>
            </div>
          `);

          banner.click(() =>
            window.initBannerCarouselRedirection({
              redirectUrl,
              redirectGroup,
              redirectPage,
              isLoggedIn,
            })
          );

          /**
           * index append 首页type banner, promo append 优惠活动type banner
           * 轮播图类型 bannerType
           * 0 - 首页
           * 1 - 优惠活动
           * 2 - 其他
           */
          if (bannerType === 0) {
            indexContainer.append(banner);
          }
        }
      });

    if (bannerList && bannerList.length) {
      var swiper = new Swiper(".banner .swiper-container", {
        autoHeight: true, //enable auto height
        pagination: {
          el: ".banner .swiper-pagination",
        },
        loop: true,
        autoplay: true,
      });
    }
  }
}

//时间数字小于10，则在之前加个“0”补位。
function check(i) {
  var num;
  i < 10 ? (num = "0" + i) : (num = i);
  return num;
}

function NowTime() {
  //获取年月日
  var time = new Date();
  var year = time.getFullYear();
  var month = time.getMonth() + 1;
  var day = time.getDate();
  var myddy = time.getDay(); //获取存储当前日期
  var weekday = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
  //获取时分秒
  var h = time.getHours();
  var m = time.getMinutes();
  var s = time.getSeconds();
  var w = weekday[myddy];

  //检查是否小于10
  month = check(month);
  day = check(day);
  h = check(h);
  m = check(m);
  s = check(s);

  return year + "-" + month + "-" + day + "&nbsp;" + h + ":" + m + ":" + s;
}

$(document).ready(function () {
  if (Pace) {
    Pace.on("done", renderBanner);
  } else {
    $(window).load(renderBanner);
  }

  //nowtime
  setInterval(function () {
    $("#nowtime").html(NowTime());
  }, 1000);

  //Load trans
  $(function () {
    var transactionsContainer = $("#transactionsContainer");
    transactionsContainer.children().remove();
    if (!!transactionsContainer.length) {
      api.getTrans(function (response) {
        response.data.result.forEach(function (info, i) {
          // console.log(info);
          transactionsContainer.append(
            $(
              `<div class="info${i == 0 ? " active" : ""}">
              恭喜会员 ${info.username} 提款 ${-info.amount} 元成功，请及时查看银行卡账目。
            </div>`
            )
          );
        });
        window.transInit();
      });
    }
  });

  // hash detection
  $(function () {
    function detectHash() {
      var currentHash = location.hash && location.hash.slice(1);
      if (currentHash.length !== 0 && currentHash != "!") {
        var box = ".faq-content-box .faq-content[data-hash=" + currentHash + "]";
        var span = ".faq-menu span[data-hash=" + currentHash + "]";
        $(box).show().siblings().hide();
        $(span).addClass("active").siblings().removeClass("active");
      }
    }

    detectHash();
  });

  if ($("#myTargetElement1").length !== 0) {
    var options = {
      useEasing: true,
      useGrouping: true,
      separator: ",",
      decimal: ".",
      prefix: "",
      suffix: "",
    };
    var demo1 = new CountUp("myTargetElement1", 0, 18, 0, 2.5, options);
    var demo2 = new CountUp("myTargetElement2", 0, 2, 0, 2.5, options);
    var demo3 = new CountUp("myTargetElement3", 0, 18, 0, 2.5, options);
    demo1.start();
    demo2.start();
    demo3.start();
  }

  //faq
  $(".faq-menu span").on("click", function () {
    var hash = $(this).attr("data-hash");
    var box = ".faq-content-box .faq-content[data-hash=" + hash + "]";
    var span = ".faq-menu span[data-hash=" + hash + "]";

    location.hash = "#" + hash;
    $(box).fadeIn().show().siblings().hide();
    $(span).addClass("active").siblings().removeClass("active");
  });

  // Bookmarks
  $("#addBookmark").click(function () {
    var f = navigator.userAgent.toLowerCase().indexOf("mac") != -1 ? "Command/Cmd" : "CTRL";
    if (document.all) {
      window.external.addFavorite(window.location.href, document.title);
    } else {
      if (window.sidebar) {
        window.sidebar.addPanel(document.title, window.location.href, "");
      } else {
        alert("该版本浏览器无法完成此操作。\n您可以尝试通过快捷键" + f + "+D加入到收藏夹~");
      }
    }
    return false;
  });

  //promo
  $(function () {
    if (window.promo) {
      renderPromo(window.promo);
    } else {
      Object.defineProperty(window, "promo", {
        set: function (data) {
          renderPromo(data);
        },
      });
    }

    function registerPromo(promoId) {
      api.registerPromo(promoId);
    }

    function renderPromo(promoData) {
      if (Array.isArray(promoData)) {
        var promoWrapper = $(".promo-wrapper");
        promoData.forEach(function (value, index) {
          var promoItem = $(`<div class="main_box" data-type="${value.type}"></div>`);

          var promoId = value.promoId;
          var requestToJoin = value.requestToJoin;
          var firstDepositPromoJoinMsg = value.firstDepositPromoJoinMsg;

          promoItem.append(
            $(`
              ${
                value.imagePath
                  ? `
              <div class="icon sub-content-item-header">
                <h3>${value.title}</h3>
                <img src="${value.imagePath}" />
              </div>
              `
                  : `
                <div class="sub-content-item-header">
                <h3>${value.title}</h3>
              </div>
                `
              }
              <div class="text nr" style="display: none;">
                ${value.content}
                ${
                  requestToJoin
                    ? `
                         <div
                           class="sub-content-item-footer"
                           style="display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; height: 50px;font-size: 14px"
                         >
                           ${
                             firstDepositPromoJoinMsg
                               ? `
                                 <div class="sub-content-item-footer--info" style="color: #555;">
                                   ${firstDepositPromoJoinMsg}
                                 </div>
                               `
                               : `
                                 <button
                                   class="sub-content-item-footer--register-promo"
                                   data-id="${promoId}"
                                   style="background-image: linear-gradient(#fdcd2f, #ffcc2f, #fefaca);cursor:pointer;color:#000;outline:0;height:35px; width:120px; border-radius:5px; border:0;"
                                 >
                                   申请参与
                                 </button>
                               `
                           }
                         </div>
                       `
                    : ``
                }
              </div>
             
            `)
          );
          promoWrapper.append(promoItem);
        });

        $(function () {
          $(".sub-content-item-footer--register-promo").on("click", function (e) {
            e.preventDefault();
            var promoId = $(this).data("id");
            registerPromo(promoId);
          });
        });

        $(document).on("click", ".main_box .sub-content-item-header", function (e) {
          e.preventDefault();
          var mainbox = $(this).closest(".main_box");
          if (mainbox.hasClass("on")) {
            mainbox.removeClass("on");
            mainbox.children(".bt").show();
            mainbox.children(".nr").slideUp();
          } else {
            mainbox.addClass("on");
            mainbox.children(".bt").hide();
            mainbox.children(".nr").slideDown();
          }
        });
      }
    }
  });
});
